import { Observable } from 'rxjs';
import { KenticoService } from '../kentico.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-full-page',
  templateUrl: './full-page.component.html',
  styleUrls: ['./full-page.component.css']
})
export class FullPageComponent implements OnInit {
  @Input() textoStc: string;
  cmsResponse$: Observable<any>;
  lang: any;
  cultureKentico: string;
  message: any;
  treatedMessage: any;
  kenticoArray: string[];

  constructor(private route: ActivatedRoute, private kenticoService: KenticoService) { }

  async ngOnInit() {
    this.kenticoArray = [this.textoStc, 'title_confirm', 'title_confirm_error', 'title_confirm_highlight', 'banner', 'banner_mobile'];
    this.lang = this.route.snapshot.params.lang;
    this.cultureKentico = this.lang + '_' + this.route.snapshot.params.country;
    this.cmsResponse$ = await this.kenticoService.getItemsObs('devoluciones', this.cultureKentico, this.kenticoArray)
  }

}
