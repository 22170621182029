import { Component, OnInit } from '@angular/core';
import { TealiumUtagService } from './tealium/utag.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [TealiumUtagService]
})
export class AppComponent implements OnInit {
  title = 'jetsmart-devoluciones-frontend' ;
  constructor(private tealium: TealiumUtagService) {
    this.tealium.setConfig({ account: environment.tealium.account, profile: environment.tealium.profile, environment: environment.tealium.environment});
  }
  ngOnInit() {
    console.log('mi front');
    this.tealium.track('view', {'event_name' : 'init'});
  }
}
