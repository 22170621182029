<main class="site-content">
  <div *ngIf="finished">
    <app-refund-modal-not-found [texto]=texto [cultureKentico]=cultureKentico></app-refund-modal-not-found>
  </div>


  <div class="container-fluid px-0">
    <div class="row no-gutters">
      <div class="col-12">
        <div [ngStyle]="{'background-image':'url(' + (cmsMainSection.banner && cmsMainSection.banner.url ? cmsMainSection.banner.url : '') + ')'}" class="img-fluid cms-banner d-none d-md-block static-header-image"></div>
        <div [ngStyle]="{'background-image':'url(' + (cmsMainSection.banner_mobile && cmsMainSection.banner_mobile.url ? cmsMainSection.banner_mobile.url : '') + ')'}" class="img-fluid cms-banner d-md-none d-block static-header-image"></div>

      </div>
    </div>
  </div>

  <h4 class="container">


    <h4 class="row">
      <h4 class="col-12 pt-5">
        <h4 class="dev_text">
          <h3 class="text-uppercase font-lato-bold mb-5">
            {{ cmsMainSection.subtitle }}
            <span class="color-light-blue-js"> {{ cmsMainSection.subtitle_help }} </span>
          </h3>
        </h4>

        <h4 class="dev_text">
          <h3 class="color-blue-js font-lato-regular">{{ cmsMainSection.terms_title }}</h3>
        </h4>
        <div *ngIf="!activatedRoute.snapshot.params.redirectToken" class="row">

<!--           <div class="col-md-6 col-12 mb-3">
            <div class="row">
              <div class="col-1 px-0">
                <h1 class="color-light-blue-js text-center">1</h1>
              </div>
              <div class="col-11 step-text text-justify">
                {{ cmsMainSection.terms_list_1 }}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-3">
            <div class="row">
              <div class="col-1 px-0">
                <h1 class="color-light-blue-js text-center">2</h1>
              </div>
              <div class="col-11 step-text text-justify">
                {{cmsMainSection.terms_list_2}}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mt-md-0 mb-3">
            <div class="row">
              <div class="col-1 px-0">
                <h1 class="color-light-blue-js text-center">3</h1>
              </div>
              <div class="col-11 step-text text-justify">
                {{cmsMainSection.terms_list_3}}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="row">
              <div class="col-1 px-0">
                <h1 class="color-light-blue-js text-center">4</h1>
              </div>
              <div class="col-11 step-text text-justify">
                {{cmsMainSection.terms_list_4}}
              </div>
            </div>
          </div> -->

          <div class="col-12">
            <p class="step-info mt-5 font-weight-bold">{{ cmsMainSection.terms_note }}</p>
            <p *ngIf="cultureKentico==='es-cl'" class="step-small-text text-justify">{{ cmsMainSection.terms_footnote }}</p>
          </div>
        </div>
      </h4>
    </h4>
  </h4>


  <section class="static-content-body">

    <div class="container">

      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="refunds">

        <div *ngIf="!activatedRoute.snapshot.params.redirectToken" class="form-group">

          <div class="row col-xs-6 col-md-12 mx-md-auto col-12 mb-1">
            

              <div class="col-md-6 col-xl-6 col-xs-12 mt-3">

                  <div class="input-group"> 

                    <input  type="text" placeholder="{{ cmsMainSection.codeemail_placeholder }}" formControlName="codeemail" name="PnrEmail" [ngClass]="{ 'is-invalid': f.codeemail.errors && errors}"
                      (change)="handleChange($event)" class="input-email form-control" aria-describedby="basic-addon2" />
    
                    <div class="input-group-append">
                      <span class="input-group-text" tooltip-class="tooltip-feedback" shadow="false"
                      tooltip="{{ cmsMainSection.search_tooltip_email }}"
                        placement="top" theme="light" id="basic-addon2">
                        <i class="material-icons">help</i>
                      </span>
                      <!-- <span tooltip="Tooltip" class="input-group-text" tooltip-class="tooltip-feedback"
                      placement="top" show-delay="500">Tooltip on top</span> -->
                    </div>
    
    
                    <div *ngIf="f.codeemail.errors && errors" class="invalid-feedback" style="color:red; margin-top: 1px;">
                      <div *ngIf="f.codeemail.errors.required ">{{ cmsMainSection.required_validation_text }}</div>
                    </div>
                  </div>
                
              </div>

              <div class="col-md-6 col-xl-6 col-xs-12 mt-3">

                  <div class="input-group">             
    
                    <input placeholder="{{ cmsMainSection.search_placeholder }}" formControlName="code" name="Pnr" [ngClass]="{ 'is-invalid': f.code.errors && errors}"
                      (change)="handleChange($event)" class="input-pnr form-control" aria-describedby="basic-addon2" />
    
                    <div class="input-group-append">
                      <span class="input-group-text" tooltip-class="tooltip-feedback" shadow="false"
                      tooltip="{{ cmsMainSection.search_tooltip_text }}"
                        placement="top" theme="light" id="basic-addon2">
                        <i class="material-icons">help</i>
                      </span>
                      <!-- <span tooltip="Tooltip" class="input-group-text" tooltip-class="tooltip-feedback"
                      placement="top" show-delay="500">Tooltip on top</span> -->
                    </div>
    
    
                    <div *ngIf="f.code.errors && errors" class="invalid-feedback" style="color:red; margin-top: 1px;">
                      <div *ngIf="f.code.errors.required ">{{ cmsMainSection.required_validation_text }}</div>
                      <div *ngIf="f.code.touched && f.code.errors.pattern">{{ cmsMainSection.format_validation_text }}</div>
                    </div>
                  </div>

              </div>

          </div>

	  
          <div class="form-group text-right">
            <div class="row">
              <div class="col-12 text-center mb-1">
                <div class="form-captcha-container mt-3">
                  <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()"
                    (load)="handleLoad()" (success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme"
                    [type]="type" formControlName="recaptcha" class="form-recaptcha" [ngClass]="{ 'is-invalid': errors && f.recaptcha.errors }">
                  </ngx-recaptcha2>
                </div>

                <div *ngIf="errors && f.recaptcha.errors" class="invalid-recaptcha" style="color:red; margin-top: 1px;">
                  <div *ngIf="f.recaptcha.errors && f.recaptcha.errors.required">{{
                    cmsMainSection.recaptcha_validation_text }}</div>
                </div>
              </div>
              <div class="col-12 text-md-right text-center mb-5">
                <button type="submit" name="submitRefunds" class="primary-btn mt-3" >{{
                  cmsMainSection.search_button_text }}</button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="activatedRoute.snapshot.params.redirectToken || (submitted && errors==false && finished===false)">

          <app-refund-list [search]=search [buscando]=buscando (finishChanged)="finishChangedHandler($event)"
            (loadedEmitter)="loadedEmitterHandler($event)" [redirectedPnr]="activatedRoute.snapshot.params.pnr" [redirectedEmail]="activatedRoute.snapshot.params.email" [redirectToken]="activatedRoute.snapshot.params.redirectToken" 
            [searchEmail]="search.email" [searchPnr]= "search.pnr">
          </app-refund-list>
        </div>

      </form>
    </div>

  </section>

</main>
