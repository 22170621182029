import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

interface seoData {
    description: string,
    title: string,
    canonical_href: string,
    fb_pageid: string,
    fb_admins: string,
    og_type: string,
    og_url: string,
    tw_accountid: string,
    tw_card: string,
    tw_url: string,
    tw_title: string,
    tw_description: string,
    tw_image: string,
    tw_imagesrc: string,
    tw_creator: string
}

@Injectable({ providedIn: 'root' })
export class SeoService {
    constructor(private titleService: Title,
                private metaTagService: Meta,
                @Inject(DOCUMENT) private dom: Document) {

    }

    setCanonicalUrl(url: string) {
        if(url) {
            let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
            if (element == null) {
                element = this.dom.createElement('link');
                this.dom.head.appendChild(element);
            }
            element.setAttribute('rel', 'canonical');
            element.setAttribute('href', url);
        }   
    }

    setMetaTags(cmsSeoData: seoData) {
        this.titleService.setTitle(cmsSeoData.title);
        this.metaTagService.addTags([
            cmsSeoData.description != "" && { name: 'description', content: cmsSeoData.description },
            cmsSeoData.fb_pageid != "" && { property: 'fb:page_id', content: cmsSeoData.fb_pageid },
            cmsSeoData.fb_admins != "" && { property: 'fb:admins', content: cmsSeoData.fb_admins },
            cmsSeoData.og_type != "" && { property: 'og:type', content: cmsSeoData.og_type },
            cmsSeoData.og_url != "" && { property: 'og:url', content: cmsSeoData.og_url },
            cmsSeoData.tw_accountid != "" && { property: 'twitter:account_id', content: cmsSeoData.tw_accountid },
            cmsSeoData.tw_card != "" && { property: 'twitter:card', content: cmsSeoData.tw_card },
            cmsSeoData.tw_url != "" && { property: 'twitter:url', content: cmsSeoData.tw_url },
            cmsSeoData.tw_title != "" && { property: 'twitter:title', content: cmsSeoData.tw_title },
            cmsSeoData.tw_description != "" && { property: 'twitter:description', content: cmsSeoData.tw_description },
            cmsSeoData.tw_image != "" && { property: 'twitter:image', content: cmsSeoData.tw_image },
            cmsSeoData.tw_imagesrc != "" && { property: 'twitter:image:src', content: cmsSeoData.tw_imagesrc },
            cmsSeoData.tw_creator != "" && { property: 'twitter:creator', content: cmsSeoData.tw_creator },
        ]);
    }
}