<div #loadingScroll class="loader-overlay-color ts-main-content-loader hidden">
  <div class="loader-container">

    <div class="loader-line">
      <div class="loader-line-wrap"></div>
    </div>
    <div class="loader-line">
      <div class="loader-line-wrap"></div>
    </div>
    <div class="loader-line">
      <div class="loader-line-wrap"></div>
    </div>
    <div class="loader-line">
      <div class="loader-line-wrap"></div>
    </div>
    <div class="loader-line">
      <div class="loader-line-wrap"></div>
    </div>
  </div>
</div>