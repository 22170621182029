import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { environment } from '../../environments/environment';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  cultures = Object;
  @ViewChild('dropdown_tag', { read: ElementRef }) dropdown: ElementRef;
  @ViewChild('dropdown_menu', { read: ElementRef }) dropdownMenu: ElementRef;
  @ViewChild('head_tag', { read: ElementRef }) header: ElementRef;
  @ViewChild('imagen', { read: ElementRef }) logo: ElementRef;
  @ViewChild('navig_tag', { read: ElementRef }) navig: ElementRef;
  prevScrollpos = 0;
  height = 0;
  logoHeight = 0;
  screenHeight = 0;
  md = 720;
  tablet = 768;
  l = 992;
  url: string;
  filteredDataFlags = [];
  @Input() cultureSelected: string;
  @Input() cmsMainSection;
  @Input() cmsSEO;

  constructor(private renderer: Renderer2, private seoService: SeoService) {
  }
  @HostListener('window:scroll', ['$event']) // La función debe estar justo debajo de esta línea
  doSomethingOnWindowsScroll($event) {
    const currentScrollPos = $event.srcElement.children[0].scrollTop;
    const screenHeight = this.screenHeight > this.md ? (this.screenHeight < this.l ? window.innerHeight * 0.37
      : window.innerHeight * 0.2) : window.innerHeight * 0.37;

    const screenHeightToHide = this.screenHeight > this.tablet ? (this.screenHeight < this.l ? window.innerHeight * 0.8
      : window.innerHeight * 0.4) : (window.innerWidth <= this.tablet ? window.innerHeight * 0.1 : window.innerHeight * 0.6);
    if (currentScrollPos > screenHeight && currentScrollPos < screenHeightToHide && this.prevScrollpos < currentScrollPos) {
      if (window.innerWidth <= this.tablet) {
        this.header.nativeElement.style.top = this.height * -1 + 'px';
      } else {
        this.header.nativeElement.style.height = this.height * 0.64 + 'px';
        this.navig.nativeElement.style.height = this.height * 0.64 + 'px';
        this.logo.nativeElement.style.height = this.logoHeight * 0.8 + 'px';
      }
      if (this.dropdown.nativeElement.classList.length > 1) {
        this.renderer.removeClass(this.dropdown.nativeElement, 'collapse');
        this.renderer.removeClass(this.dropdown.nativeElement, 'show');
      }
    }
    if (this.prevScrollpos > currentScrollPos) {
      if (window.innerWidth > this.tablet) {
        this.header.nativeElement.style.height = this.height + 'px';
        this.navig.nativeElement.style.height = this.height + 'px';
      }
      this.logo.nativeElement.style.height = this.logoHeight + 'px';
    }
    if (currentScrollPos > screenHeightToHide && this.prevScrollpos < currentScrollPos) {
      if (this.dropdown.nativeElement.classList.length > 1) {
        this.renderer.removeClass(this.dropdown.nativeElement, 'collapse');
        this.renderer.removeClass(this.dropdown.nativeElement, 'show');
      }
      if (this.dropdownMenu.nativeElement.classList.length > 2) {
        this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
      }
      this.header.nativeElement.style.top = this.height * -1 + 'px';
    }
    if (currentScrollPos > screenHeightToHide && currentScrollPos < this.prevScrollpos) {
      this.header.nativeElement.style.top = '0';
      // console.log(`current ${currentScrollPos} , previus ${this.prevScrollpos} , screenHide ${screenHeightToHide} ,
      // screenHeigh ${screenHeight}`);
    }
    this.prevScrollpos = currentScrollPos;
  }
  onResize(event: Event = new Event('')) {
    // console.log('se cambió el tamaño de la pantalla', event.target.innerWidth, event.target.innerHeight);

    this.screenHeight = window.innerWidth;
    // console.log('altura header ', this.header.nativeElement.offsetHeight);
    this.header.nativeElement.style.top = '0';
    if (window.innerWidth > this.tablet) {
      this.header.nativeElement.style.height = '90px';
      this.navig.nativeElement.style.height = '86px';
      this.renderer.setStyle(this.navig.nativeElement, 'margin-right', '0px');
      this.renderer.setStyle(this.navig.nativeElement, 'margin-left', '0px');
      this.height = this.header.nativeElement.offsetHeight;
      // this.header.nativeElement.style.height = '90px';
    } else {
      this.header.nativeElement.style.height = '68px';
      this.navig.nativeElement.style.height = '64px';
      this.renderer.setStyle(this.navig.nativeElement, 'margin-right', '10px');
      this.renderer.setStyle(this.navig.nativeElement, 'margin-left', '10px');
      this.height = this.header.nativeElement.offsetHeight;
    }
  }
  onClickMenu() {
    this.renderer.removeStyle(this.header.nativeElement, 'height');
    this.renderer.removeStyle(this.navig.nativeElement, 'height');
  }
  ngOnInit() {
    this.screenHeight = window.innerWidth;
    this.url = environment.url ? environment.url : 'http://d2ybwjb0pf86sj.cloudfront.net/';
    this.cultures = this.cmsMainSection.flagsData;
    this.filteredDataFlags = Object.values(this.cmsMainSection.flagsData);
    this.seoService.setMetaTags(this.cmsSEO);
    this.seoService.setCanonicalUrl(this.cmsSEO.canonical_href);
  }

  loadedImg(img) {
    this.logoHeight = img.clientHeight;
    this.height = this.header.nativeElement.offsetHeight;
    this.onResize();
  }
}
