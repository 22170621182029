import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { EmailConfirmComponent } from './email-confirm/email-confirm.component';
import { MainComponent } from './main/main.component';

const cultures = ['cl/es', 'pe/es', 'ar/es', 'us/en', 'co/es', 'br/pt', 'uy/es', 'py/es', 'ec/es'];

export function urlMatcher(url) {
  console.log('url ', url);
  if (url.length !== 3) {
    return null;
  }
  const url_cult = url[0].path + '/' + url[1].path;
  if (cultures.indexOf(url_cult) === -1) {
    return null;
  } else {
    return {
      consumed: url, posParams: {
        lang: new UrlSegment(url[1].path, {}), country: new UrlSegment(url[0].path, {})
      }
    };
  }
}
export function urlMatcherConfirm(url) {
  if (url.length !== 6) {
    return null;
  }
  const url_cult = url[0].path + '/' + url[1].path;
  if (cultures.indexOf(url_cult) === -1) {
    return null;
  } else {
    return {
      consumed: url, posParams: {
        lang: new UrlSegment(url[1].path, {}), country: new UrlSegment(url[0].path, {}),
        pnr: new UrlSegment(url[4].path, {}), token: new UrlSegment(url[5].path, {})
      }
    };
  }
}

const routes: Routes = [
  {
    path: ':country/:lang/devoluciones/redirect/:pnr/:email/:redirectToken',
    component: MainComponent
  },
  {
    matcher: urlMatcher,
    component: MainComponent
  },
  {
    matcher: urlMatcherConfirm,
    component: EmailConfirmComponent
  },
  {
    path: ':country/:lang/devoluciones/confirm/:pnr/:token',
    redirectTo: 'cl/es/devoluciones/confirm/:pnr/:token'
  },
  {
    path: '**/main',
    pathMatch: 'full',
    redirectTo: 'cl/es/devoluciones/main'
  },
  {
    path: '**',
    redirectTo: 'cl/es/devoluciones'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
