import { Injectable } from '@angular/core';
@Injectable()
export class TealiumUtagService {
  scriptSrc = '';

  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor() {
    ( window as any).utag_cfg_ovrd = { noview : true };
    ( window as any).utag_data = {
      fb_pid : "", // 
      PNR : "", // 
      accept_language : "", // 
      additional_cookie : "", // 
      breakdown_ancillaries_amount : "", // 
      breakdown_currency : "", // 
      breakdown_due_amount : "", // 
      breakdown_fare_amount : "", // 
      breakdown_tax_amount : "", // 
      breakdown_total_amount : "", // 
      browser : "", // 
      browser_version : "", // 
      checkin_status_inbound : "", // 
      checkin_status_outbound : "", // 
      city_code : "", // 
      collection_currency : "", // 
      collection_total : "", // 
      corporate_release : "", // 
      country_code : "", // 
      culture : "", // 
      dclub_group_currency : "", // 
      dclub_group_price : "", // 
      dclub_standard_currency : "", // 
      dclub_standard_price : "", // 
      destination_selector_values : "", // 
      device_brand : "", // 
      device_model : "", // 
      device_type : "", // D - Desktop,T - tablet,M - mobile
      error_code : "", // 500,422,404,...
      error_info : "", // Detail of error
      flow_type : "", // DB - During Booking,PB - Post Booking,CK - Checkin
      fop_brand : "", // 
      fop_code : "", // 
      ibe_release : "", // 
      inbound_arrival_iata : "", // 
      inbound_availability_days : "", // 3 month days availability (calendar)
      inbound_departure_iata : "", // 
      inbound_fare_price : "", // 
      inbound_fare_currency : "", // 
      inbound_flights_availability : "", // Available inbound flights: inventory type,flight number,departure date,arrival date,regular flight price,regular flight currency,dclub flight price,dclub flight currency. Separated by pipe
      inbound_seat_availability : "", // 
      inbound_seat_selected : "", // Seat's code,Seat's price,Seat's currency. Separated by pipe
      inbound_selected_flight : "", // Selected inbound flight: inventory type,flight number,departure date,arrival date,flight price,flight currency. Separated by pipe
      installment_availability : "", // True/False if either is enabled for that FoP
      installment_options_availability : "", // Options available for current FoP selected
      installment_selected : "", // 
      interest_currency : "", // 
      interest_fee : "", // Interest fee for current installment option selected
      interest_rate : "", // Interest rate for current installment option selected
      last_payment_amount : "", // Last amount paid (not the total of flight)
      last_payment_status : "", // Last status of payment Approved,Rejected,Pending
      market_code : "", // 
      organization : "", // Organization where user belongs
      outbound_arrival_iata : "", // 
      outbound_availability_days : "", // 3 month days availability (calendar)
      outbound_departure_iata : "", // 
      outbound_fare_price : "", // 
      outbound_fare_currency : "", // 
      outbound_flights_availability : "", // Available inbound flights: inventory type,flight number,departure date,arrival date,regular flight price,regular flight currency,dclub flight price,dclub flight currency. Separated by pipe
      outbound_seat_availability : "", // 
      outbound_seat_selected : "", // Seat's code,Seat's price,Seat's currency. Separated by pipe
      outbound_selected_flight : "", // Selected outbound flight: inventory type,flight number,departure date,arrival date,flight price,flight currency. Separated by pipe
      page : "", // 
      page_version : "", // 
      pax_adult_count : "", // 
      pax_children_count : "", // 
      pax_infant_count : "", // 
      preloaded_promocode : "", // 
      price : "", // 
      price_currency : "", // 
      price_info : "", // 
      program : "", // 
      promocode : "", // 
      referer : "", // 
      referral : "", // 
      session_create_date : "", // 
      sessionid : "", // 
      ssrs_code_available_inbound : "", // These are the SSRs available for add-to-cart in a particular page. In baggage page,it would be all baggage SSR available for purchase
      ssrs_code_available_outbound : "", // 
      ssrs_code_included_inbound : "", // SSR's code,SSR's price,SSR's currency. Separated by pipe
      ssrs_code_included_outbound : "", // SSR's code,SSR's price,SSR's currency. Separated by pipe
      standard_price : "", // 
      standard_price_currency : "", // 
      tealium_event : "", // Tealium variable to identify unique events and page types eg. home,product_view,cart_add,user_login,email_signup,etc.
      user_programs : "", // 
      user_role : "", // 
      useragent : "", // 
      voucher_amount : "", // 
      voucher_amount_used : "", // Amount used of the voucher or giftcard
      voucher_code : "", // 
      voucher_currency : "", // 
      pax_set_array : "", // 
      page_name : "", // Contains a user-friendly name for the page.
      page_section : "", // Contains a user-friendly page section, e.g. 'configuration section'.
      page_category : "", // Contains a user-friendly page category, e.g. 'appliance page'.
      page_subcategory : "", // Contains a user-friendly page subcategory, e.g. 'refrigerator page'.
      page_type : "", // Contains a user-friendly page type, e.g. 'cart page'.
      customer_id : "", // Contains the customer's ID.
      customer_username : "", // Contains the customer's username, e.g. johndoe38.
      customer_status : "", // Contains the customer's status, e.g. 'logged in'.
      customer_type : "", // Contains the customer's type, e.g. 'bronze member'.
      customer_email : "", // Contains the customer's email.
      customer_city : "", // Contains the city portion of the customer's address.
      customer_state : "", // Contains the state portion of the customer's address.
      customer_zip : "", // Contains the zip code portion of the customer's address.
      customer_country : "", // Contains the country portion of the customer's address.
      search_term : "", // Contains the search term or query submitted by a visitor.
      search_results : "", // Contains the number of results returned with a search query.
      search_type : "", // Contains the type of search conducted by the visitor.
      product_id : "", // Contains product ID(s) - multiple values should be comma-separated.
      product_sku : "", // Contains product SKU(s) - multiple values should be comma-separated.
      product_name : "", // Contains product name(s) - multiple values should be comma-separated.
      product_brand : "", // Contains product brand(s) - multiple values should be comma-separated.
      product_category : "", // Contains product category(s) - multiple values should be comma-separated.
      product_subcategory : "", // Contains product subcategory(s) - multiple values should be comma-separated.
      product_unit_price : "", // Contains product unit price(s) - multiple values should be comma-separated.
      product_list_price : "", // Contains product list price(s) before discount - multiple values should be comma-separated.
      product_quantity : "", // Contains product quantity(s) - multiple values should be comma-separated.
      product_discount : "", // Contains product discount(s) - multiple values should be comma-separated.
      order_id : "", // Contains the order or transaction ID.
      order_total : "", // Contains the order total value.
      order_subtotal : "", // Contains the order subtotal (not containing taxes &amp; shipping).
      order_payment_type : "", // Contains the order payment type.
      order_discount : "", // Contains the order discount (if any).
      order_shipping : "", // Contains the order shipping value.
      order_tax : "", // Contains the order tax amount.
      order_currency : "", // Contains the currency associated with the transaction, e.g. 'USD'.
      order_coupon_code : "", // Contains the order coupon code.
      order_coupon_discount : "", // Contains the order coupon discount.
      order_store : "", // Contains identification information for a store.
      order_type : "", // Contains type of order/cart.
      site_region : "", // Contains values for the region or localized version, e.g. 'en_US'.
      article_id : "", // Contains the article's ID.
      article_name : "", // Contains the article's name.
      article_author : "", // Contains the name of the author of an article.
      article_publish_date : "", // Contains the publish date of an article.
      order_number : "", // 
      payment_id : "", // 
      number_of_installments : "", // 
      authorization_code : "", // 
      transaction_date : "", // 
      last_4_digits : "", // 
      amount : "", // 
      curency_code : "", // 
      quoted_currency : "", // 
      quoted_total : "", // 
      availability : "", // 
      inbound_availability : "", // 
      outbound_availability : "", // 
      auto_opened : "", // 
      currency : "", // 
      currency_code : "", // 
      gads_id : "", // 
      gads_label : "", // 
      ampl_event_name : "", // 
      station_code : "", // 
      modify : "", // 
      trip_type : "", // 
      availability_day : "", // 
      date : "", // 
      fare : "", // 
      baggage_availability_outbound : "", // 
      baggage_included_outbound : "", // 
      baggage_availability_inbound : "", // 
      baggage_included_inbound : "", // 
      pax_index : "", // 
      carrier_code : "", // 
      airline : "", // 
      flight_number : "", // 
      departure : "", // 
      arrival : "", // 
      origin : "", // 
      destination : "", // 
      dclub_price : "", // 
      dclub_price_currency : "", // 
      fare_type : "", // 
      fare_info : "", // 
      departure_iata : "", // 
      pax_type : "", // 
      direction : "", // 
      inbound_direction : "", // 
      outbound_direction : "", // 
      quantity : "", // 
      previous_quantity : "", // 
      outbound_inventory_type : "", // 
      inbound_inventory_type : "", // 
      seat_number : "", // 
      previous_seat_number : "", // 
      previous_price : "", // 
      previous_currency : "", // 
      previous_type : "", // 
      previous_direction : "", // 
      previous_amount : "", // 
      card_mask : "", // 
      amount_due_total : "", // 
      amount_due_currency : "", // 
      leg : "", // 
      third_party_type : "", // 
      role : "", // 
      type : "", // 
      departure_date : "", // 
      outbound_departure_date : "", // 
      outbound_arrival_date : "", // 
      outbound_price_info : "", // 
      inbound_price_info : "", // 
      inbound_arrival_date : "", // 
      messages : "", // 
      transaction_date_utc : "", // 
      transaction_id : "", // 
      resolution : "", // 
      load_type : "", // 
      fop_code_availability : "", // 
      inbound_departure_date : "", // 
      tealium_visitor_id : "", // 
      route : "", // 
      outbound_fare_departure_date : "", // 
      inbound_fare_departure_date : "", // 
      outbound_fare_departure_iata : "", // 
      outbound_fare_arrival_iata : "", // 
      outbound_fare_arrival_date : "", // 
      inbound_fare_arrival_date : "", // 
      event_name : "", // Tealium variable to identify unique events eg. social_share, user_login, etc.
      fb_flight_click : "", // flight page continue click
      ampl_id : "", // 
      inbound_fare_departure_iata : "", // 
      inbound_fare_arrival_iata : "", // 
      origin_iata : "", // 
      destination_iata : "", // 
      user_dc_level : "", // 
      membership_type : "", // 
      inbound_route : "", // 
      device : "", // 
      total_ancillary : "", // 
      total_tax : "", // 
      outbound_bundle : "", // 
      inbound_bundle : "", // 
      contact_email : "", // 
      soicos_pid : "", // 
      ga_action : "", // 
      ga_category : "", // 
      utm_value : "", // 
      is_gds_booking : "", // Boolean for type gds
      inbound_fare_currency_code : "", // 
      outbound_fare_currency_code : "", // 
      route_outbound_fs : "" // 
    };
  }

  // Generic script loader with callback
  getScript( src: string, callback: () => void ) {
    const d = document;
    const fn = () => {};
    const o = { callback: callback || fn };
    let s: any;
    let t: any;

    if ( typeof src === 'undefined' ) {
      return;
    }

    s = d.createElement('script'); s.language = 'javascript'; s.type = 'text/javascript'; s.async = 1; s.charset = 'utf-8'; s.src = src;
    if ( typeof o.callback === 'function' ) {
      if ( d.addEventListener ) {
        s.addEventListener('load', () => {o.callback(); }, false);
      } else {
        // old IE support
        s.onreadystatechange = function(){
          if (this.readyState === 'complete' || this.readyState === 'loaded'){this.onreadystatechange = null; o.callback(); }
        };
      }
    }
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  // Config settings used to build the path to the utag.js file
  setConfig(config: {account: string, profile: string, environment: string}) {
    if ( config.account !== undefined && config.profile !== undefined && config.environment !== undefined ) {
      this.scriptSrc = 'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
    }
  }

  // Data layer is optional set of key/value pairs
  track(tealiumEvent: string, data?: any) {
    if ( this.scriptSrc === '' ) {
      console.log('Tealium config not set.');
      return;
    }
    if ( ( window as any).utag === undefined ) {
      this.getScript( this.scriptSrc, () => {
        ( window as any).utag.track( tealiumEvent, data );
      });
    } else {
      ( window as any).utag.track( tealiumEvent, data );
    }
  }

  view(data?: any) {
    this.track('view', data);
  }

  link(data?: any) {
    this.track('link', data);
  }
}