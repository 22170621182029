import { Injectable } from '@angular/core';

import { ContentItem, DeliveryClient } from 'kentico-cloud-delivery';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KenticoService {

  constructor() { }

  getItems(customType: string, documentLang: string, fields: Array<string> = []): Promise<any> {
    // tslint:disable-next-line:prefer-const
    let deliveryClientConfig = <any>{
      projectId: environment.kenticoID,
      enablePreviewMode: environment.kenticoEnablePreviewMode
    };

    if (environment.kenticoEnablePreviewMode === true) {
      deliveryClientConfig.previewApiKey = environment.kenticoPreviewApiKey;
    }
    const deliveryClient = new DeliveryClient(deliveryClientConfig);

    return new Promise<any>((resolve, reject) => {
      try {
        deliveryClient.items()
          .type(customType)
          .languageParameter(documentLang)
          .elementsParameter(fields)
          .getObservable()
          .subscribe(response => {
            const documents = {};
            let flagsData = [];
            let metadata = {};
            if (customType === 'devoluciones' || customType === 'header') { // Se obtiene variables CMD de header o devoluciones
              response.items[0].flags && response.items[0].flags.forEach(elem => {
                let idioma = "";
                if(elem.elements.name.value == "us") {
                  idioma = 'en';
                } else if (elem.elements.name.value == "br") {
                  idioma = 'pt';
                } else {
                  idioma = 'es';
                }
                
                let cultura = `${idioma}_${elem.elements.name.value}`
                flagsData[cultura] = {
                  name: elem.elements.name.value,
                  img_url: elem.elements.img.value[0].url,
                  country: elem.elements.country.value,
                  lang: idioma,  
                }
              })
              Object.keys(response.items[0].elements).map(elemento => {
                if(elemento.includes('metadata__')){
                  metadata[response.items[0].elements[elemento].name] = response.items[0].elements[elemento].value;
                }
              });
              Object.keys(response.items[0].elements).map((elemento) => {
                if (response.items[0].elements[elemento].name === 'banner'
                || response.items[0].elements[elemento].name === 'banner_mobile') {
                  documents[response.items[0].elements[elemento].name] = response.items[0].elements[elemento].value[0];
                } else {
                  const cmsValue = typeof response.items[0].elements[elemento].value === 'string' ?
                    response.items[0].elements[elemento].value.replace('<BR>', '<br>') : response.items[0].elements[elemento].value
                  documents[response.items[0].elements[elemento].name] = cmsValue;                    
                }
              });
              documents['flagsData'] = flagsData
              documents['metadata'] = metadata
              resolve(documents);
            } else { // Se obtiene variables CMS Footer
              Object.keys(response.linkedItems.map((elemento) => {
                  documents[elemento.system.codename] = elemento.elements;
              }
              ));
              documents['payment_title'] = response.items[0]['payment_title'];
              documents['col_title_1'] = response.items[0]['col_title_1'];
              documents['col_title_2'] = response.items[0]['col_title_2'];
              documents['col_title_3'] = response.items[0]['col_title_3'];
              documents['col_title_4'] = response.items[0]['col_title_4'];
              documents['rrss_title'] = response.items[0]['rrss_title'];
              resolve(documents);
            }
          });
      } catch (e) {
        console.error(`Error during get document from Kentico API: ${e}`);
      }

    });

  }

  getItemsObs(customType: string, documentLang: string, fields: Array<string> = []): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let deliveryClientConfig = <any>{
      projectId: environment.kenticoID,
      enablePreviewMode: environment.kenticoEnablePreviewMode
    };
    if (environment.kenticoEnablePreviewMode === true) {
      deliveryClientConfig.previewApiKey = environment.kenticoPreviewApiKey;
    }
    const deliveryClient = new DeliveryClient(deliveryClientConfig);
    return deliveryClient.items()
          .type(customType)
          .languageParameter(documentLang)
          .elementsParameter(fields)
          .getObservable();
  }
}

