<div *ngIf="loaded===false">
  <app-loading></app-loading>
</div>

<div *ngIf="loaded===true">
  <app-header [cmsMainSection]=cmsHeader [cultureSelected]=cultureKentico></app-header>
</div>

<div *ngIf="loaded===true && modal===true && (!msgStc || msgStc && msgStc === '')">
  <app-refund-modal-not-found [texto]=msg  [cultureKentico]=cultureKentico></app-refund-modal-not-found>
</div>

<div *ngIf="loaded===true && msgStc && msgStc !== ''">
  <app-full-page class='full-page' [textoStc]='msgStc' ></app-full-page>
</div>

<!--<div *ngIf="loaded===true && (!msgStc || (msgStc && msgStc === ''))">-->
  <!--<app-refund-search *ngIf="" [cmsMainSection]=cmsMainSection></app-refund-search>-->
<!--</div>-->

<div *ngIf="loaded===true">
  <app-footer [cmsMainSection]=cmsFooter></app-footer>
</div>
