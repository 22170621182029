import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { RefundSearchComponent } from './refund-search/refund-search.component';
import { RefundListComponent } from './refund-list/refund-list.component';
import { RefundModalNotFoundComponent } from './refund-modal-not-found/refund-modal-not-found.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { EmailConfirmComponent } from './email-confirm/email-confirm.component';
import { MainComponent } from './main/main.component';
//import { Ng2Rut } from 'ng2-rut';
import { LoadingComponent } from './loading/loading.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TooltipModule } from 'ng2-tooltip-directive';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CL';
import { FormComponent } from './form/form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FullPageComponent } from './full-page/full-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

registerLocaleData(localeEs, 'es');
@NgModule({
  declarations: [
    AppComponent,
    RefundSearchComponent,
    RefundListComponent,
    RefundModalNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    EmailConfirmComponent,
    MainComponent,
    LoadingComponent,
    FormComponent,
    FullPageComponent
  ],
  imports: [
    BrowserModule,
   // Ng2Rut,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxCaptchaModule,
    TooltipModule,
    ServiceWorkerModule.register('sw.js', { enabled: true })
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'es' } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
