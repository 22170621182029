import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InternalCommunicationService } from './../internal-communication.service';
import { Location } from '@angular/common';
import { RefundService } from '../refund.service';
import { Subscription } from 'rxjs';
import { KenticoService } from '../kentico.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-confirm.component.html',
  styleUrls: ['./email-confirm.component.scss']
})
export class EmailConfirmComponent implements OnInit {
  cmsMainSection = Object;
  cmsFooter = Object;
  cmsHeader = Object;
  cultureKentico: string;
  lang: string;
  loaded = false;
  msg = '';
  msgStc = '';
  error = false;
  modal = false;
  subscription: Subscription;
  static: boolean;

  constructor(
    private route: ActivatedRoute, private location: Location, private reservaService: RefundService,
    private internalMsg: InternalCommunicationService, private kenticoService: KenticoService,
  ) { }

  ngOnInit() {
    // console.log('ActivatedRoute ', this.route);
    this.lang = this.route.snapshot.params.lang;
    this.cultureKentico = this.lang + '_' + this.route.snapshot.params.country;
    Promise.all([
      this.getFooterCMS(),
      this.getHeaderCMS(),
      this.getListFormCMSSections(),
      this.getCode()
    ]).then(() => {
      this.loaded = true;
      // this.loadedEmitter.emit(this.loaded);
    });
  }
  getCode(): void {
    const pnr = this.route.snapshot.paramMap.get('pnr');
    const ConfirmToken = this.route.snapshot.paramMap.get('token');
    if (pnr && ConfirmToken) {
      this.reservaService.confirmEmailValidate({pnr, ConfirmToken})
      .then(resp => {
        console.log('Respuesta de API GateWAY ', resp);
        const modalText = resp.body && resp.body.msg ? resp.body.msg : ''; // : environment.modalConfirmEmail[resp.status];
        const textStatic = resp.body && resp.body.msg_stc ? resp.body.msg_stc : '';
        if (resp.status !== 200) {
          this.msg = `ERROR:
          `;
        } else {
         // this.msg = `Solicitud creada:
         // `;
        }
        this.msg = modalText;
        this.msgStc = textStatic;
        this.modal = true;
        this.subscription = this.internalMsg.getMessage().subscribe(msg => {
          this.modal = false;
        });
        if (resp.status !== 200) {
          this.error = true;
        }
      });
    }
  }
  getListFormCMSSections(): Promise<any> {
    const retrieveElements = ['title',
      'banner',
      'subtitle',
      'subtitle_help',
      'terms_title',
      'terms_list_1',
      'terms_list_2',
      'terms_list_3',
      'terms_list_4',
      'terms_note',
      'terms_footnote',
      'search_placeholder',
      'search_tooltip_text',
      'search_button_text',
      'required_validation_text',
      'format_validation_text',
      'recaptcha_validation_text'];
    return new Promise<void>((resolve, reject) => {
      this.kenticoService.getItems('devoluciones', this.cultureKentico, retrieveElements)
      .then(resp => {
        console.log('Respuesta Kentico ', resp);
        this.cmsMainSection = resp ? resp : {};
        resolve();
      });
    });
  }
  getHeaderCMS(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.kenticoService.getItems('header', this.cultureKentico, [])
      .then(resp => {
        // console.log('Respuesta Kentico Header', resp);
        this.cmsHeader = resp ? resp : {};
        resolve();
      });
    });
  }
  getFooterCMS(): Promise<any> {
    const retrieveElements = [];
    return new Promise<void>((resolve, reject) => {
      this.kenticoService.getItems('footer', this.cultureKentico, retrieveElements)
        .then(resp => {
          // console.log('Respuesta Kentico Footer ', resp);
          this.cmsFooter = resp ? resp : {};
          resolve();
        });
    });
  }
}
