export const COUNTRIES: string[] = ['Chile', 'Argentina', 'Brasil', 'Colombia', 'Peru', 'Uruguay', 'Paraguay', 'USA', 'Ecuador','Otros'];
export const CHILE: string = 'Chile';
export const ARGENTINA: string = 'Argentina';
export const OTROS: string = 'Otros';
export const USA: string = 'USA';
export const COLOMBIA: string = 'Colombia';
export const PERU: string = 'Peru';
export const URUGUAY: string = 'Uruguay';
export const BRASIL: string = 'Brasil';
export const PARAGUAY: string = 'Paraguay';
export const NO: string = 'NO';
export const CREATED: string = 'CREATED';
export const EMAIL_SENT: string = 'EMAIL_SENT';
export const ECUADOR: string = 'Ecuador';

export const LENGTH_FIELDS = {
    CCI: { name: 'CCI', length: 20 },
    RUC: { name: 'RUC', length: 11 },
    DNI: { name: 'DNI', length: 8 },
    CBU: { name: 'CBU', length: 22 },
    CPF: { name: 'CPF', length: 11 }
};


export const getMsgLengthFields = (field: string, length: number, msg: string): string => {
    let newMsg = replaceAll(msg, "${field}", field);
    newMsg = replaceAll(newMsg, "${length}", length.toString());
    return newMsg
};

export const replaceAll = (input: string, search: string, replacement: string): string => {
    return input.split(search).join(replacement);
  }

