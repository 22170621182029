import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { KenticoService } from '../kentico.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() cmsMainSection;
  @Input() itemsFooter;
  @ViewChild('instagram') private instagramRef: ElementRef;
  instagramHref: any;

  itemsFooter$: Observable<any>;
  rsUrls$: Observable<any>;
  rsClass$: Observable<any>;
  cultureObj: {
    lng: string;
    culture: string
  };
  constructor(private sanitizer: DomSanitizer, private kenticoService: KenticoService, private _route: ActivatedRoute) {

  }

  ngOnInit() {

    // console.log('this._route.snapshot.params :', this._route.snapshot.params);

    this.itemsFooter$ = this.kenticoService.getItemsObs(
      'footer', this._route.snapshot.params.lang + '_' + this._route.snapshot.params.country, []);

    this.rsUrls$ = this.itemsFooter$.pipe(map(e => e.items[0].rrss__links.map( x =>
        x.value.value.match(/"([^"]*)"/ig))));

    this.rsClass$ = this.rsUrls$.pipe(map(e => e.map( x =>
        x[0].replace(/^"|"$/g, ''))));

    this.instagramHref = this.sanitizer.bypassSecurityTrustUrl(this.cmsMainSection.instagram.url.value);
  }
}
