  <div *ngIf="cmsResponse$ | async as cmsResponse" class="container-fluid px-0 banner">
        <div class="row no-gutters">
            <div class="col-12">
              <img src="{{cmsResponse.items[0].banner.value[0].url}}" class="img-fluid cms-banner d-none d-md-block static-header-image">
              <img src="{{cmsResponse.items[0].banner_mobile.value[0].url}}" class="img-fluid cms-banner d-md-none d-block static-header-image">
            </div>
        </div>
    </div>

  <div *ngIf="cmsResponse$ | async as cmsResponse" class="container">
      <div class="row">
          <div class="col-12 pt-5">
            <div class="dev_text">
              <h3 class="text-uppercase font-lato-bold mb-5" *ngIf="!textoStc.includes('error')">
                  {{cmsResponse.items[0].title_confirm.value}}
                  <span class="color-light-blue-js">{{cmsResponse.items[0].title_confirm_highlight.value}}</span>
              </h3>
              <h3 class="text-uppercase font-lato-bold mb-5" *ngIf="textoStc.includes('error')">
                {{cmsResponse.items[0].title_confirm_error.value}}
              </h3>
              <h5 class="font-lato-regular">{{cmsResponse['items'][0][textoStc]['value']}}</h5>
            </div>
          </div>
      </div>
  </div>

