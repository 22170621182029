import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { KenticoService } from '../kentico.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  cmsMainSection = Object;
  cmsFooter = Object;
  cmsHeader = Object;
  cultureKentico: string;
  lang: string;
  loaded = false;
  cmsSEO = {};

  @Output() loadedEmitter: EventEmitter<boolean> = new EventEmitter();
  constructor(private kenticoService: KenticoService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    // console.log('ActivatedRoute ', this.activatedRoute);
    this.lang = this.activatedRoute.snapshot.params.lang;
    // console.log('this.activatedRoute.snapshot.params :', this.activatedRoute.snapshot.params);
    this.cultureKentico = this.lang + '_' + this.activatedRoute.snapshot.params.country;
    Promise.all([
      this.getListFormCMSSections(),
      this.getFooterCMS(),
      this.getHeaderCMS()
    ]).then(() => {
      this.loaded = true;
      this.loadedEmitter.emit(this.loaded);
    });
  }
  getFooterCMS(): Promise<any> {
    const retrieveElements = [];
    return new Promise<void>((resolve, reject) => {
      this.kenticoService.getItems('footer', this.cultureKentico, retrieveElements)
        .then(resp => {
          //  console.log('Respuesta Kentico Footer ', resp);
          this.cmsFooter = resp ? resp : {};
          resolve();
        });
    });
  }
  getListFormCMSSections(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.kenticoService.getItems('devoluciones', this.cultureKentico, [])
      .then(resp => {
        this.cmsSEO = resp.metadata;
        
        this.cmsMainSection = resp ? resp : {};
        resolve();
      });
    });
  }
  getHeaderCMS(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.kenticoService.getItems('header', this.cultureKentico, [])
      .then(resp => {
        // console.log('Respuesta Kentico Header', resp);
        this.cmsHeader = resp ? resp : {};
        resolve();
      });
    });
  }
}
