import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {PitUtils} from './pit-utils';

export class PitValidators {


  static rutDv(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.length > 0) {
      const datos = PitUtils.parseRutDv(control.value);
      if (!PitUtils.validarRutDv(datos.rut, datos.dv)) {
        return {invalidRutDv: true};
      }
    }
    return null;
  }

  static rutDvStr(rut: string): boolean {
    if (rut && rut.length > 0) {
      const datos = PitUtils.parseRutDv(rut);
      if (!PitUtils.validarRutDv(datos.rut, datos.dv)) {
        return false;
      }
    }
    return true;
  }

  static isValidRuc (rucStr: string): boolean{

    if(!PitUtils.isOnlyNumber(rucStr)) return false

    const validInitialNumbers = [10,15,16,17,20];
    let firstNumbers = parseInt(rucStr.slice(0,2));
    let lastNumber = parseInt(rucStr.slice(rucStr.length - 1));
    
    if(!validInitialNumbers.includes(firstNumbers)) return false;

    let multiplier = 5;
    let sum = 0;
    for(let i = 0; i < rucStr.length - 1; i++) {
        sum += (parseInt(rucStr[i]) * multiplier);
        multiplier = multiplier == 2 ? 7 : multiplier - 1;
    }
    let rest = 11 - (sum % 11);
    if(rest > 9 ) rest -= 10;
    if(rest == lastNumber) return true;
    return false;
  }

  static ValidRuc(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.length > 0) {
      const datos = PitValidators.isValidRuc(control.value);
      if(control.value.length != 11) {
        return {invalidRucLength: true};
      }else if (!datos) {
        return {invalidRuc: true};
      }
    }
    return null;
  }

  static ValidRucEcuador(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.length > 0 && control.value.length != 13) {
      return {invalidRucLength: true};
    }
    return null;
  }

  

  static ValidCCI(control: AbstractControl): ValidationErrors | null {
    if(control.value.length != 20) {
      return {invalidCCILength: true};
    } else if ( 
        ((control.value && control.value.length > 0 ) && (control.value.match(/^[0-9]+$/) == null) && (control.value && control.value.length < 20)) ||
        ((control.value && control.value.length > 20) && (control.value.match(/^[0-9]+$/) == null) )  ||
        ((control.value && control.value.length > 0 ) && (control.value && control.value.length < 20)  ) ||
        ((control.value && control.value.length == 20) && (control.value.match(/^[0-9]+$/) == null) )  ||
        ((control.value && control.value.length > 20)) ||
        !PitUtils.isOnlyNumber(control.value)
      ) {
        return {invalidCCI: true};
    }
    return null;
  }

  static ValidDNI(control: AbstractControl): ValidationErrors | null {
    if (control.value.length != 8) {
      return {invalidDNILength: true};
    } else if ( ((control.value && control.value.length > 0) && (control.value && control.value.length < 8)) ||
    (control.value && control.value.length > 8) ||
    !PitUtils.isOnlyNumber(control.value)) {
      return {invalidDNI: true};
    }
    return null;
  }
  

  static isValidIBAN (ibanStr: string): boolean{
    let CODE_LENGTHS : any = {
       AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
       CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
       FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
       HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
       LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
       MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
       RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
       AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
       SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
   };

   let iban = String(ibanStr).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
           code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
           digits;
   // check syntax and length
   if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
       return false;
   }
   // rearrange country code and check digits, and convert chars to ints
   digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter):any {
       return letter.charCodeAt(0) - 55;
   });
   // final check
   return this.mod97(digits);
   
 }

  static mod97 (value: any): any{
    let checksum = value.slice(0, 2), fragment;
   for (let offset = 2; offset < value.length; offset += 7) {
       fragment = String(checksum) + value.substring(offset, offset + 7);
       checksum = parseInt(fragment, 10) % 97;
   }
   let result = checksum !== 1 ? false : true
   return result;
   

}

static validIBAN(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.value.length > 1) {
    const datos = PitValidators.isValidIBAN(control.value);
    if (!datos) {
      return {invalidIBAN: true};
    }
  }
  return null;
}

/* ********************************************
          Validacion Rut Uruguay 
 **********************************************/

static generateValidationDigit: ( idNumber: string | number ) => number = idNumber => {
  let ci = typeof idNumber === 'number' ? idNumber.toString() : idNumber;

  for (let i = 0; i < 7 - ci.length; i++) {
    ci = '0' + ci;
  }

  return PitValidators.safeGenerateValidationDigit(ci);
};

static safeGenerateValidationDigit: (idNumber: string) => number = idNumber => {
  let ci = idNumber;
  let sum = 0;

  for (let i = 0; i < 7; i++) {
    sum += parseInt('2987634'[i]) * parseInt(ci[i]);
  }

  if (sum % 10 === 0) {return 0;}

  return 10 - (sum % 10);
};
 
static cleanIdNumber: (idNumber: string) => string = idNumber =>
  idNumber.replace(/\D/g, '');
  
static isValidRutUy: ( idNumber: string | number ) => boolean = idNumber => {
  const ci = typeof idNumber === 'number' ? idNumber.toString() : idNumber;

  if (ci.length < 7 || ci.length > 8) {
    return false;
  }

  const cleanNumber = PitValidators.cleanIdNumber(ci);

  const possibleValidationDigit = parseInt(
    cleanNumber[cleanNumber.length - 1],
    10
  );

  const validableNumber = cleanNumber.replace(/[0-9]$/, '');
  const actualValidationDigit = PitValidators.generateValidationDigit(validableNumber);
  return possibleValidationDigit === actualValidationDigit;
};

static validRutUy(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.value.length > 0) {
    const datos = PitValidators.isValidRutUy(control.value);
    if (!datos) {
      return {invalidRutUy: true};
    }
  }
  return null;
}

static isEmpty(control: AbstractControl): ValidationErrors | null {

  return control.value.replaceAll(' ','') === '' ? {isEmpty: true} : null;
}

static validateCBU(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.value.length > 0) {
    if(control.value.length != 22){
      return {invalidCBULength: true}
    } else if(!PitUtils.isOnlyNumber(control.value )) {
     return {invalidCBU: true}
    } 
  }
  return null;
}

static validateCPF(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.value.length > 0) {
    if(control.value.length != 11){
      return {invalidCPFLength: true}
    } else if(!PitUtils.isOnlyNumber(control.value )) {
      return {invalidCPF: true}
    }
  }
  return null;
}


}
