// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Environment:
const environmentUser: any = 'cert';
let pathBase,urlFront;
let tealiumEnvironment;
// Se definen las variables para el ambiente
if (environmentUser === 'bvr') {
  pathBase = 'https://api-devoluciones.sf.jetsmart.dev/devoluciones';
  urlFront = 'https://devoluciones.sf.jetsmart.dev/';
  // pathBase = 'http://localhost:3000/dev/devoluciones';
  
  //pathBase = 'https://e8vr53zyb9.execute-api.us-east-2.amazonaws.com/cert/devoluciones';
  // pathBase = 'https://4mrz3ja1d2.execute-api.us-east-2.amazonaws.com/dev/devoluciones';
  //pathBase = 'https://cpblfhe56e.execute-api.us-east-2.amazonaws.com/dev/devoluciones';
  //  urlFront = 'http://localhost:4200/';

  tealiumEnvironment = 'dev';
} else if (environmentUser === 'cert') {
  pathBase = 'https://api-devoluciones.cert.jetsm.art/devoluciones';
  urlFront = 'http://devo.cert.jetsm.art/';
  tealiumEnvironment = 'qa';
}else if (environmentUser === 'dev') {
  pathBase = 'https://api-devoluciones.sf.jetsmart.dev/devoluciones';
  urlFront = 'http://dgzbgb5wn83db.cloudfront.net/';
  tealiumEnvironment = 'dev';
}

console.log(`Ambiente : ${environmentUser}`);

const urlGetBookingInfo = '/get-booking-info';
const urlListBanks = '/banks';
const urlGetAccountType = '/accounts';
const idtypescol = '/idtypescol';
const urlCreateRefundCase = '/create-refund-case';
const urlConfirmEmailValidate = '/confirm-email-validate';
const tealiumAccount = 'jetsmart';
const tealiumProfile = 'main';

export const environment = {
  production: false,
  getBookingInfo: {
    url: pathBase + urlGetBookingInfo,
    method: 'POST'
  },
  listBanks: {
    url: pathBase + urlListBanks + '?countryCode=',
    method: 'GET'
  },
  getAccountType: {
    url: pathBase + urlGetAccountType + '?countryCode=',
    method: 'GET'
  },
  getTypesCol: {
    url: pathBase + idtypescol + '?countryCode=',
    method: 'GET'
  },
  createRefundCase: {
    url: pathBase + urlCreateRefundCase,
    method: 'POST'
  },
  confirmEmailValidate: {
    url: pathBase + urlConfirmEmailValidate,
    method: 'POST'
  },
  modalTexts: {
    204: `Hola, te informamos que en nuestros registros no contamos con información asociada al código de reserva ingresado,
     por verifica tu código nuevamente o comunícate con nuestro Contact Center desde Chile al 6006001311, Perú 1 311 0005,
     Argentina 11 2206 7799, desde celulares u otros países +56 22 731 8787.`,
    201: `Estimado usuario, creamos su solicitud de devolución de forma exitosa.
     Favor revise su correo electrónico (_EMAIL_) para confirmar esta solicitud.`,
    400: `Estimado usuario, tuvimos un problema al crear su solicitud de devolución,
    debido a que la información ingresada no es correcta. Favor intente de  nuevo.`,
    409: `Hola, te informamos que en nuestros registros no contamos con información asociada al código de reserva ingresado,
     por verifica tu código nuevamente o comunícate con nuestro Contact Center desde Chile al 6006001311, Perú 1 311 0005,
     Argentina 11 2206 7799, desde celulares u otros países +56 22 731 8787.`,
    500: `Estimado usuario, ha ocurrido un error interno al intentar
    procesar su solicitud de devolución. Favor intente más tarde o
     contáctese con nuestro Contact Center desde Chile al 6006001311, Perú 1 311 0005,
     Argentina 11 2206 7799, desde celulares u otros países +56 22 731 8787.`,
    502: `Estimado usuario, ha ocurrido un error al intentar
    enviar el correo electrónico de confirmación de su solicitud de devolución.
     Favor contáctese con nuestro Contact Center desde Chile al 6006001311, Perú 1 311 0005,
     Argentina 11 2206 7799, desde celulares u otros países +56 22 731 8787.`,
    504: `error_internal_server_timeout`
  },
  modalConfirmEmail: {
    200: `Estimado usuario, la solicitud de devolución ha sido creada de forma exitosa. Una
    vez terminado el proceso de devolución se lo notificaremos por correo electrónico.`,
    402: `Estimado usuario, en nuestros registros no contamos con
    información que nos permita confirmar que al código de reserva
    consultado le corresponda una devolución, o la dirección URL expiró.
     Favor intente más tarde o contáctese con nuestro contact center 600 600 1311.`,
    403: `Estimado usuario, en nuestros registros no contamos con
    información que nos permita confirmar que al código de reserva
    consultado le corresponda una devolución, o la dirección URL expiró.
     Favor intente más tarde o contáctese con nuestro Contact Center desde Chile al 6006001311, Perú 1 311 0005,
     Argentina 11 2206 7799, desde celulares u otros países +56 22 731 8787.`,
    409: `Estimado usuario, en nuestros registros no contamos con
    información que nos permita confirmar que al código de reserva
    consultado le corresponda una devolución, o la dirección URL expiró.
     Favor intente más tarde o contáctese con nuestro Contact Center desde Chile al 6006001311, Perú 1 311 0005,
     Argentina 11 2206 7799, desde celulares u otros países +56 22 731 8787.`
  },
  captchaPublicKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  countryCodes: {
    Chile: 'CL',
    Argentina: 'AR',
    Colombia: 'CO',
    Brasil: 'BR',
    Peru: 'PE',
    Uruguay: 'UY',
    Paraguay: 'PY',
    Ecuador: 'EC',
    Otros: 'OT'
  },
  kenticoID: 'b2956330-c34f-0064-2c6f-27bd5c0147fc',
  kenticoEnablePreviewMode: true,
  // tslint:disable-next-line:max-line-length
  kenticoPreviewApiKey: 'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjQ4ZDcyZDcyZTk3NTRlOTM5M2NlZTI5Yjg1YzMwMzJiIiwNCiAgImlhdCI6ICIxNjEwOTk3ODI3IiwNCiAgImV4cCI6ICIxOTU2NTk3ODI3IiwNCiAgInByb2plY3RfaWQiOiAiYjI5NTYzMzBjMzRmMDA2NDJjNmYyN2JkNWMwMTQ3ZmMiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.zb6ADh0_T4uUaEC7T8rLdkVlKCTXCiBv__lvxCuMRQY',
  // '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // '6LfT94gUAAAAALslWIJUaZ6ZyuZc7UTeJQzMYm8t'
  url: urlFront,
  logLevel: "debug",
  modalRedirect: {
    secondsTimeout: 5000
  },
  tealium: {
    account: tealiumAccount,
    profile: tealiumProfile,
    environment: tealiumEnvironment
  }
};
