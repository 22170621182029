import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit, AfterViewInit {
  @Input() doScroll = true;
  @ViewChild('loadingScroll', { read: ElementRef }) loadingScroll: ElementRef;
  ngAfterViewInit(): void {
    if (this.doScroll) {
      try {
        this.loadingScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } catch (e) {
        console.log(e);
      }
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
