import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';

import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RefundService } from '../refund.service';

@Component({
  selector: 'app-refund-search',
  templateUrl: './refund-search.component.html',
  styleUrls: ['./refund-search.component.scss']
})
export class RefundSearchComponent implements OnInit {
  registerForm: UntypedFormGroup;
  submitted = false;
  errors = false;
	pnr: string;
	email: string;
	search: { pnr: string, email: string, Recaptcha?: string, redirectToken?: string};
  finished = false;
  buscando = 0;
  public readonly siteKey = environment.captchaPublicKey;
  public theme = 'light';
  public size = 'normal';
  public lang: string;
  public type = 'image';
  texto = 'Solicitud de devolución creada de forma exitosa. Por favor revise el correo para confirmar esta solicitud.';
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  captchaSuccess = false;
  captchaResponse?: string;
  captchaIsExpired = false;
  captchaIsLoaded = false;
  @Input() cmsMainSection;
  country: string;
  cultureKentico: string;

  constructor(private formBuilder: UntypedFormBuilder, public activatedRoute: ActivatedRoute, private refundService: RefundService
    ) { }
  ngOnInit() {
    if (this.activatedRoute.snapshot.params.redirectToken) {
      console.log('viene token');
      this.submitted = true;
      this.errors = false;
      this.finished = false;
      this.search = {
        pnr: this.activatedRoute.snapshot.params.pnr,
	      email: this.activatedRoute.snapshot.params.email,
        redirectToken: this.activatedRoute.snapshot.params.redirectToken
      };
      this.buscando++;
      this.onSubmit();
    }
    console.log({submitted: this.submitted, errors: this.errors, finished: this.finished, search: this.search});
    this.registerForm = this.formBuilder.group({
			code: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{6}$')]],
			codeemail:['', Validators.required],	
      recaptcha: ['', Validators.required]
    });

    this.lang = this.activatedRoute.snapshot.params.lang;
    this.cultureKentico = this.lang + '_' + this.activatedRoute.snapshot.params.country;
  }

  get f() { return this.registerForm.controls; }
  finishChangedHandler(finished: any) {
    this.finished = !this.finished;
  }
  loadedEmitterHandler(loaded: any) {
    this.registerForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{6}$')]],
			codeemail:['', Validators.required],	
      recaptcha: ['', Validators.required]
    });
  }
  handleChange(e) {
  }
  onSubmit() {
    this.submitted = true;
     console.log('buscando...');
    // inválido
    if (!this.activatedRoute.snapshot.params.redirectToken) {
      if (this.registerForm.invalid) {
      this.errors = true;
      this.submitted = false;
      // console.log('onSubmit invalid', this.registerForm);
      return;
      }
    }
    this.errors = false;
    this.finished = false;
    if (!this.activatedRoute.snapshot.params.redirectToken) {
      this.pnr = this.registerForm.value.code.toUpperCase();
      this.email = this.registerForm.value.codeemail;
      this.search = {pnr: this.pnr, email:this.email, Recaptcha: this.captchaResponse};
      this.reset();

      console.log('this.pnr :', this.pnr);
      console.log('this.email :', this.email);
      console.log('onSubmit ', JSON.stringify(this.registerForm.value));
    }
     
  }
  // reCaptcha functions
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  reset(): void {
    // this.captchaElem.resetCaptcha();
    this.captchaElem.reCaptchaApi.reset();
  }
}
